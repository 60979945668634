import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';

import { CountrySelector } from '@/components/utility/microcomponents/CountrySelector';
import { Country } from '@/constants/Countries';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import AccountAPI from '@/network/AccountAPI';
import UserTracking from '@/services/UserTracking';
import { handleApiError } from '@/utility/api';

import SpotifySearchArtist from '../components/utility/microcomponents/SpotifySearchArtist';
import { auth } from '../firebaseSetup';

enum Steps {
  USER,
  ARTIST,
}

const UserStep = ({ setStep }: { setStep: Dispatch<SetStateAction<Steps>> }) => {
  const { t } = useTranslation();
  const { dispatchFirebaseAccountId } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('GB');

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: auth.currentUser?.email,
    terms: false,
    marketing: true,
  };

  const { register, control, watch } = useForm({
    defaultValues,
  });
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const terms = watch('terms');
  const marketing = watch('marketing');

  const formInvalid = useMemo(
    () => !(firstName !== '' && lastName !== '' && terms && countryCode !== ''),
    [countryCode, firstName, lastName, terms]
  );

  const createAccount = useCallback(async () => {
    setCreatingAccount(true);
    try {
      if (!auth.currentUser || !auth.currentUser.email) return;

      const data = {
        userId: auth.currentUser?.uid,
        countryCode: countryCode,
        contact: {
          firstName,
          lastName,
          email: auth.currentUser?.email,
        },
        marketingAccepted: marketing,
      };
      await AccountAPI.createAccount({ data }).then(async (resp) => {
        await dispatchFirebaseAccountId(resp.data.userId);
        const tracking = new UserTracking({ account: resp.data });
        tracking.userSignUp();
      });
      setStep(Steps.ARTIST);
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setCreatingAccount(false);
    }
  }, [countryCode, dispatchFirebaseAccountId, dispatchSnackbar, firstName, lastName, marketing, setStep]);

  return (
    <>
      <div className="card max-w600 ml-auto mr-auto mt20">
        <form className="text-left">
          <div className="user-form-names">
            <label>
              <p>First name *</p>
              <input
                {...register('firstName', { required: true })}
                placeholder="John"
                name="firstName"
                data-testid="firstname"
              />
            </label>
            <label>
              <p>Last name *</p>
              <input
                {...register('lastName', { required: true })}
                placeholder="Smith"
                name="lastName"
                data-testid="lastname"
              />
            </label>
          </div>

          <label>
            <p className="mt16">Email address</p>
            <input {...register('email')} disabled={true} placeholder="Enter email address" name="email" />
          </label>

          <div className="w100p">
            <p className="mt16 mb8">Location</p>
            <CountrySelector
              preselectedCountry={'GB'}
              outputCountry={(country: Country) => {
                setCountryCode(country.abbr);
              }}
            />
          </div>

          <FormControlLabel
            className="mt16 text-left"
            label={<p className="pt8">{t('ONBOARDING.MARKETING-DESCRIPTION')}</p>}
            control={
              <Controller
                name="marketing"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="playlist-checkbox"
                    data-testid="ts-and-cs-checkbox"
                    name="playlist-checkbox"
                    icon={<div className="checkbox-icon"></div>}
                    checkedIcon={
                      <div className="checkbox-icon">
                        <div className="checked"></div>
                      </div>
                    }
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
          />

          <FormControlLabel
            className=""
            label={
              <p className="pt8">
                Click here to agree to our{' '}
                <span>
                  <a href="https://www.unhurd.co.uk/partnerterms" target="blank">
                    Terms & Conditions
                  </a>
                </span>
              </p>
            }
            control={
              <Controller
                name="terms"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id="playlist-checkbox"
                    data-testid="ts-and-cs-checkbox"
                    name="playlist-checkbox"
                    icon={<div className="checkbox-icon"></div>}
                    checkedIcon={
                      <div className="checkbox-icon">
                        <div className="checked"></div>
                      </div>
                    }
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
          />
        </form>
      </div>
      <Button className="btn-white mt32" onClick={createAccount} disabled={creatingAccount || formInvalid}>
        {t('COMMON.CONTINUE')}
      </Button>
    </>
  );
};

const ArtistStep = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();

  const { accountId, refetchAccount } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [updatingAccount, setUpdatingAccount] = useState<boolean>(false);

  const defaultValues = {
    spotifyArtist: undefined,
  };

  const { setValue, watch } = useForm<{ spotifyArtist: SpotifyArtistSearchModel }>({
    defaultValues,
  });

  const spotifyArtist = watch('spotifyArtist');

  useEffect(() => {
    if (accountId) return;
    refetchAccount();
  }, [accountId, refetchAccount]);

  const updateAccount = useCallback(async () => {
    setUpdatingAccount(true);
    try {
      if (!accountId) return;

      const data = {
        spotifyArtistId: spotifyArtist.id,
        emailValidated: true,
      };
      await AccountAPI.updateAccount({ accountId, data });
      window.location.href = `${window.location.origin}`;
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar });
    } finally {
      setUpdatingAccount(false);
    }
  }, [accountId, dispatchSnackbar, spotifyArtist]);

  useEffect(() => {
    setSearchParams({ artist: 'true' });
  }, [setSearchParams]);

  return (
    <>
      <div className="card max-w600 ml-auto mr-auto mt20">
        <SpotifySearchArtist spotifyArtist={(artist: SpotifyArtistSearchModel) => setValue('spotifyArtist', artist)} />
      </div>
      <Button className="btn-white mt32" onClick={updateAccount} disabled={!spotifyArtist || updatingAccount}>
        {updatingAccount ? <CircularProgress size={16} /> : t('COMMON.CONTINUE')}
      </Button>
    </>
  );
};

const OnboardingPage = () => {
  const { t } = useTranslation();
  const { account } = useAccountContext();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [step, setStep] = useState<Steps>(Steps.USER);

  useEffect(() => {
    if (account && account.artistId) {
      navigate('/');
    }

    if ((account && !account.artistId) || params.get('artist')) {
      setStep(Steps.ARTIST);
    }
  }, [account, navigate, params]);

  return (
    <div data-testid="onboarding-page" className="w100p text-center">
      <h1 className="mt120">{t('ONBOARDING.LETS-GET-YOU-STARTED')}</h1>
      <h3 className="text-faded mt20">{t('ONBOARDING.WE-JUST-NEED-A-FEW-DETAILS')}</h3>
      {step === Steps.USER && <UserStep setStep={setStep} />}
      {step === Steps.ARTIST && <ArtistStep />}
    </div>
  );
};

export default OnboardingPage;
